import { Card, Container, Stack, Text, VStack, HStack, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Setting() {
    return (
        <Container maxWidth='9xl'>

            <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacing={4}
                w={{base:'100%', md:'50%'}}
            >
                <Link to={'/setting/push-notification'}>
                    <Card mt='5' boxShadow={'lg'}>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'center'} py={4}>
                                <Text className='custom' fontSize='lg' color='primary.900'>Push Notifications</Text>
                            </VStack>
                        </HStack>
                    </Card>
                </Link>

                <Link to={'/setting/your-profile'}>
                    <Card mt='5' boxShadow={'lg'}>
                        <HStack width='100%' justifyContent='space-between' p='5'>
                            <VStack w={'100%'} alignItems={'center'} py={4}>
                                <Text className='custom' fontSize='lg' color='primary.900'>Your Profile</Text>
                            </VStack>
                        </HStack>
                    </Card>
                </Link>
            </SimpleGrid>
        </Container>
    )
}
