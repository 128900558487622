import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  HStack,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  IconButton,
  Link,
  Checkbox,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoIosMail, IoIosLock } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { toast } from "react-toastify";

export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { loginOtp } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        await loginOtp(values.email, values.password);
        navigate('/otp-page', { state: values });
      } catch (error) {
        toast.error("Invalid Credentials");
        console.error(error);
      }
    }
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      w="100vw"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgImage="url('./login.jpg')"
      bgSize="cover"
      bgPosition="center"
      bgAttachment="fixed"
      overflow="hidden" 
    >
      <Box
        bg="rgba(255, 255, 255, 0.3)"
        p={8}
        boxShadow="lg"
        border={'1px'}
        borderRadius={'xl'}
        borderColor={'#FFFFFF'}
        w={{ base: '90%', md: '400px' }}
        position="relative" 
        overflow="hidden"  
      >
        <VStack spacing={4} align="center" mb={8}>
          <Box
            bgImage="url('./rainbow.png')"
            bgSize="contain"
            bgPosition="center"
            bgRepeat="no-repeat"
            width="75px"
            height="75px"
            position="absolute"
            top="10%"
            left="calc(50% + 100px)"
            transform="translateY(-50%)"
            zIndex={1}
            overflow="hidden" 
          />
          <Box
            position="relative"
            width="full"
            height="80px"
            textAlign="center"
            overflow="hidden" 
          >
            <Box
              bgImage="url('./auth_bg.png')"
              bgSize="contain"  
              bgPosition="center"
              bgRepeat="no-repeat"
              width="190px"
              height="60px"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              zIndex={1}
            />
            <Text
              fontSize="3xl"
              fontWeight="bold"
              position="relative"
              zIndex={2}
              color="black"
              display="inline-block"
              pt={4}
            >
              Welcome!
            </Text>
          </Box>
          <Text fontSize="xl" fontWeight="bold">Welcome! Let’s Get Started!</Text>
        </VStack>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={4}>
            <FormControl id="email">
              <FormLabel fontWeight={'400'} fontSize={'xs'} color={'#FFFFFF'}>Email Address</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  mt={2}
                  children={<IoIosMail color="gray.300" />}
                />
                <Input
                  id="email"
                  name="email"
                  type="email"
                  variant="filled"
                  placeholder="example@mail.com"
                  fontSize={'sm'}
                  p={6}
                  pl={8}
                  _focus={{
                    borderColor: "primary.800",
                    bg: '#FFFFFF'
                  }}
                  borderRadius={'50'}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="password">
              <FormLabel fontWeight={'400'} fontSize={'xs'} color={'#FFFFFF'}>Password</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  mt={2}
                  children={<IoIosLock color="gray.300" />}
                />
                <Input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter Password"
                  bg="whiteAlpha.800"
                  variant="filled"
                  fontSize={'sm'}
                  p={6}
                  pl={8}
                  _focus={{
                    borderColor: "primary.800",
                    bg: '#FFFFFF'
                  }}
                  borderRadius={'50'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                <InputRightElement width="4.5rem">
                  <IconButton
                    mt={2}
                    h="1.75rem"
                    size="sm"
                    onClick={togglePasswordVisibility}
                    icon={showPassword ? <FiEye /> : <FiEyeOff />}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <HStack justify="space-between" w="100%">
              <Checkbox
                id="keep-logged-in"
                color={'#FFFFFF'}
              >
                <Text fontWeight={'400'} fontSize={'xs'}>
                  Keep Me Logged In
                </Text>
              </Checkbox>
              {/* <Link color="#FFFFFF" fontWeight={'600'} fontSize={'xs'}>
                Forgot Password?
              </Link> */}
            </HStack>
            <Button
              type="submit"
              bg="primary.900"
              color="#FFFFFF"
              w="100%"
              p={6}
              borderRadius="18px 0px 18px 18px"
              fontSize={'sm'}
              fontWeight={'400'}
              _hover={{
                borderColor: "primary.800",
                bg: '#FFFFFF',
                color: 'primary.900'
              }}
            >
              Send OTP
            </Button>
          </VStack>
        </form>
      </Box>
    </Box>
  );
}
