import {
    Modal,
    ModalContent,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    InputGroup,
    InputLeftElement,
    FormControl,
    Text,
    HStack,
    Container,
    Center,
    ModalBody,
    useDisclosure,
    Image,
} from '@chakra-ui/react';
import { IoSearch } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoChevronBackOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { useNotificationGroup } from '../../providers/NotificationGroupProvider';
import { useEffect, useState } from 'react';
import { ConfirmDialog } from '../../components/utils/ConfirmDialog';
import AddMoreUsers from './AddMoreUser';

export default function ViewList({ isOpen, onClose, group }) {
    const { usersFromGroup, deleteUserFromGroup, searchUserFromGroup } = useNotificationGroup();

    const { isOpen: isAddMoreUsersOpen, onOpen: onAddMoreUsersOpen, onClose: onAddMoreUsersClose } = useDisclosure();

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);
    const [searchQuery, setSearchQuery] = useState("");

    const handleConfirm = async () => {
        try {
            await deleteUserFromGroup(group._id, selectedId);
            setIsConfirmDialogOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsConfirmDialogOpen(false);
        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    useEffect(() => {
        searchUserFromGroup(group._id, searchQuery).then(() => {
        });
    }, [searchQuery]);

    useEffect(()=>{
        console.log(usersFromGroup)
    })

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={'xl'}>
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader fontSize={'md'} fontWeight={'500'} w='100%'>
                        <HStack mr={{ base: 2, md: 8 }} justifyContent={'space-between'}>
                            <HStack>
                                <IoChevronBackOutline onClick={onClose} />
                                <Container ml={{ md: 2 }} bg='primary.800' h={8} w={8} borderRadius={50} alignItems={'center'}>
                                    <Center h="100%">
                                        {group.name ?
                                            <Text fontWeight={'500'} fontSize={'sm'} color={'white'}>
                                                {group.name.charAt(0).toUpperCase()}
                                            </Text>
                                            :
                                            <></>
                                        }
                                    </Center>
                                </Container>
                                <VStack ml={1} spacing={0} alignItems={'start'}>
                                    <Text fontSize={'sm'}>{group.name}</Text>
                                    <Text fontSize={'xs'} color={'grey'}>{group.userCount} users</Text>
                                </VStack>
                            </HStack>

                            <Link onClick={() => onAddMoreUsersOpen()}>
                                <Text fontSize={{ base: 'xs', md: 'sm' }} color='primary.800'>Add More Users</Text>
                            </Link>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody maxH="70vh" px={6} mb={6}>
                        <VStack spacing={6}>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <IoSearch size={'18px'} />
                                    </InputLeftElement>
                                    <Input
                                        id="searchUser"
                                        name="searchUser"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        placeholder="Search User"
                                        fontSize={{ base: 'xs', md: 'sm' }}
                                        py={4}
                                        px={10}
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </InputGroup>
                            </FormControl>

                            <VStack w={'100%'} h={'250px'} spacing={2} maxH="50vh" overflowY="auto">
                                {usersFromGroup.map((u, index) => (
                                    <HStack key={index} w={'100%'} justifyContent={'space-between'} mb={4} pr={4}>
                                        <HStack w={'100%'}>
                                            {u.user_id.profile_picture ? (
                                                <Image
                                                    src={u.user_id.profile_picture}
                                                    w={'33px'}
                                                    h={'33px'}
                                                    alt='user'
                                                    border={'2px'}
                                                    borderColor='primary.800'
                                                    borderRadius="50%"
                                                    boxShadow={'lg'}
                                                />
                                            ) : (
                                                <Image src='../user_demo.png' width={'32px'} alt='user' />
                                            )}
                                            <VStack ml={1} spacing={0} alignItems={'start'}>
                                                <Text fontSize={'sm'}><Text fontSize={'sm'}> {u.user_id.first_name ?
                                                <Text pl={{ base: 2, md: 0 }}>
                                                    {u.user_id.first_name} {u.user_id.last_name}
                                                </Text>
                                                : <Text>NA</Text>}</Text></Text>
                                                <Text fontSize={'xs'} color={'grey'}>{u.user_id.email}</Text>
                                            </VStack>
                                        </HStack>

                                        <Link onClick={() => { setSelectedId(u.user_id._id); setIsConfirmDialogOpen(true); }}>
                                            <RiDeleteBin6Line color='red' size={18} />
                                        </Link>
                                    </HStack>
                                ))}
                            </VStack>
                        </VStack>
                    </ModalBody>
                </ModalContent>
                <AddMoreUsers isOpen={isAddMoreUsersOpen} onClose={onAddMoreUsersClose} group={group} />

                <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                    title="Are you sure?"
                    body="This action cannot be undone."
                />
            </Modal>
        </>
    );
}
