import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = extendTheme({
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        borderRadius: 'lg',
      },
    },
  },
  colors: {
    "primary": {
      "50":  "#BBDCFA",
      "100": "#6EC0E5",
      "200": "#198AF3",
      "300": "#147DDE",
      "400": "#0E62B0",
      "500": "#5C73E6",
      "600": "#3B49E7",
      "700": "#3654EA",
      "800": "#4C47DF",
      "900": "#012535"
    },
    "secondary": "#E16317",
    "grey": {
      "50": "#fef5fa",
      "100": "#e3e3e3",
      "200":"#5E5E5E",
      "300":"#4B4B4B",
    },
  },
  styles: {
    global: {
      'html, body': {
        bg: 'white',
      },
    },
  },
})

// removing logs in prod 
if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);


