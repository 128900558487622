import {
    Button,
    Container,
    HStack,
    Icon,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure,
    Image
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiPlusCircle } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
import PushNotification from '../../models/push_notifications/PushNotification';
import CreateNewNotification from '../../models/push_notifications/CreateNewNotification';
import { useScheduledNotifications } from "../../providers/ScheduledNotificationProvider";
import { ConfirmDialog } from '../utils/ConfirmDialog';

export default function PushNotifications() {
    const { scheduledNotifications, deleteScheduledNotification } = useScheduledNotifications();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isNotificationOpen, onOpen: onNotificationOpen, onClose: onNotificationClose } = useDisclosure();
    const { isOpen: isCreateNotificationOpen, onOpen: onCreateNotificationOpen, onClose: onCreateNotificationClose } = useDisclosure();

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const handleConfirm = async () => {
        try {
            await deleteScheduledNotification(selectedId);
            setIsConfirmDialogOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            onClose();
        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    let formattedJoinedDate;

    useEffect(()=>{
        console.log(scheduledNotifications)
    })

    return (
        <>
            <Container maxWidth='9xl' px={6}>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    mt='5'
                    w={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500">
                        <Link to='/setting'>
                            Settings &gt;{' '}
                        </Link>
                        <Text as="span" color="black" pl={2} fontWeight={700}>
                            Push Notifications
                        </Text>
                    </Text>

                    <Link onClick={() => onCreateNotificationOpen()}>
                        <HStack
                            p={{ base: '4', md: 6 }}
                            bg={'primary.900'}
                            color={'white'}
                            borderRadius={{ base: '22px', md: '22px', lg: '42px' }}
                            width={{ base: '100%', md: 'auto' }}
                            mt={{ base: 4, md: 0 }}
                            justifyContent={'center'}
                        >

                            <FiPlusCircle size={'22px'} />

                            <Text fontSize={{ md: 'sm', lg: 'md' }} fontWeight={'500'}>
                                Create New Notification
                            </Text>
                        </HStack>
                    </Link>

                </Stack>


                <VStack w='100%' alignItems={'start'} mt={8}>
                    <Text className='custom' fontSize="lg" fontWeight="500">
                        Send Notifications
                    </Text>

                    <PushNotification />
                </VStack>

                <VStack w={'100%'} alignItems={'start'} mt={8} spacing={4}>
                    <Text className='custom' fontSize="lg" fontWeight="500">
                        Scheduled Notification List
                    </Text>

                    <Stack direction={{ base: 'column', lg: 'row' }} w={'100%'} alignItems={'start'}>

                        <TableContainer w={{ base: '100%', lg: "100%" }} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                            <Table variant="simple" >
                                <Thead>
                                    <Tr bg="primary.100">
                                        <Th>Date</Th>
                                        <Th>Image</Th>
                                        <Th>Title</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody
                                    sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                                >
                                    {scheduledNotifications.map((s, index) => (
                                        formattedJoinedDate = formatDate(s.date),
                                        <Tr key={index} fontSize="sm">
                                            {/* <Td>#123</Td> */}
                                            <Td>{formattedJoinedDate}</Td>
                                            {s.image ?
                                                <Container pl={{base:0, md:0, lg:6}} mt={2}>
                                                    <Image src={s.image} w="12" h="12" objectFit="cover" />
                                                </Container>
                                                :
                                                <Td>No Image</Td>
                                            }
                                            <Td>{s.title}</Td>
                                            <Td>
                                                <Button
                                                    colorScheme='red'
                                                    leftIcon={<Icon as={RxCrossCircled} boxSize={4} />}
                                                    borderRadius='50px'
                                                    fontWeight={500}
                                                    fontSize={'xs'}
                                                    onClick={() => { setSelectedId(s._id); setIsConfirmDialogOpen(true); }}
                                                >
                                                    Delete
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}

                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </VStack>

                <CreateNewNotification isOpen={isCreateNotificationOpen} onClose={onCreateNotificationClose} />

                <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                    title="Are you sure?"
                    body="This action cannot be undone."
                />
            </Container>
        </>
    )
}


