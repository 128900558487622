import React from 'react';
import {
    Container,
    Text,
    SimpleGrid,
    VStack,
    Button,
    Stack,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    Image
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { FaRegUserCircle } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAdminMembers } from '../../providers/AdminProvider';

export default function ViewAdminAccount() {
    const { state } = useLocation();

    const { updateAdminMember, deleteAdmin } = useAdminMembers();

    const formik = useFormik({
        initialValues: {
            f_name: state.first_name,
            l_name: state.last_name,
            email: state.email,
            role: "Admin",
            bio: state.bio
        },
        onSubmit: async (values) => {
            await updateAdminMember(state._id, values.f_name, values.l_name, values.bio);
        }
    });

    return (
        <>
            <Container maxWidth='9xl' px={6}>
                <Text className='custom' fontSize="lg" fontWeight="500">
                    <Link to='/admin-management'>
                        Admin Management &gt;{' '}
                    </Link>
                    <Text as="span" color="black" pl={2} fontWeight={700}>
                        {state.first_name}
                    </Text>
                </Text>

                {/* <Text mt={8} w={{ base: '100%', md: '80%' }} textAlign={'end'} color={'red'}>Delete Account? </Text> */}

                <Stack direction={{ base: 'column', md: 'row' }} mt={8} w={'100%'} spacing={12}>
                    <VStack spacing={0} ml={{ md: 16 }}>
                        {state.profile_picture ?
                            (<Image
                                src={state.profile_picture}
                                w={'130px'}
                                h={'130px'}
                                border={'2px'}
                                borderColor='primary.800'
                                borderRadius="50%"
                                boxShadow={'lg'}
                            />)
                            : (
                                <Image src='../user_demo.png' width={'120px'} alt='user' />
                            )}
                        <Text fontSize="xl" fontWeight="800" color="primary.800">{state.first_name}</Text>
                        <Text fontSize="sm">{state.email}</Text>
                    </VStack>

                    <form onSubmit={formik.handleSubmit}>
                        <VStack w={'100%'} alignItems={'start'} ml={{ md: 12 }}>
                            <SimpleGrid w={'100%'} columns={{ base: 1, lg: 2 }} spacing={8}>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegUserCircle color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="f_name"
                                            name="f_name"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            placeholder="First Name"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.f_name}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegUserCircle color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="l_name"
                                            name="l_name"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            placeholder="Last Name"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.l_name}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<IoIosMail color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            variant="filled"
                                            required={true}
                                            placeholder="Email ID"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            isDisabled
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegUserCircle color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="role"
                                            name="role"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            placeholder="Role Type: Admin"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.role}
                                            onChange={formik.handleChange}
                                            isDisabled
                                        />
                                    </InputGroup>
                                </FormControl>
                            </SimpleGrid>

                            <Textarea
                                mt={4}
                                id="bio"
                                name="bio"
                                type="text"
                                variant="filled"
                                placeholder="Bio"
                                fontSize="md"
                                py={4}
                                px={4}
                                bg="none"
                                borderColor="gray.100"
                                _focus={{
                                    borderColor: "primary.800",
                                }}
                                borderRadius="10"
                                value={formik.values.bio}
                                onChange={formik.handleChange}
                            />

                            <Container>
                                <Button
                                    mt={6}
                                    type="submit"
                                    bg="primary.700"
                                    color={'white'}
                                    py='6'
                                    width="full"
                                    borderRadius={'50'}
                                    fontSize={'sm'}
                                >
                                    Update Account
                                </Button>
                            </Container>

                        </VStack>
                    </form>
                </Stack>
            </Container>
        </>
    )
}
