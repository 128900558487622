import React, { useState } from 'react';
import {
    Card,
    Container,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Stack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useDisclosure,
    Image
} from '@chakra-ui/react';
import { FiPlusCircle } from "react-icons/fi";
import { TfiMoreAlt } from "react-icons/tfi";
import { Link, useNavigate } from 'react-router-dom';
import CreateNewAdmin from '../../models/CreateNewAdmin';
import { useAdminMembers } from "../../providers/AdminProvider";
import { ConfirmDialog } from '../utils/ConfirmDialog';

export default function AdminManagement() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { adminMembers, deleteAdmin } = useAdminMembers();

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);

    const handleConfirm = async () => {
        try {
            await deleteAdmin(selectedId);
            setIsConfirmDialogOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            onClose();
        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    return (
        <>
            <Container maxWidth='9xl' px={6}>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    mt='5'
                    w={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Text className='custom' fontSize="lg" fontWeight="500">
                        Admin Management
                    </Text>

                    <Link onClick={() => onOpen()}>
                        <HStack
                            p={{ base: '4', md: 6 }}
                            bg={'primary.700'}
                            borderRadius={{ base: '28px', md: '22px', lg: '42px' }}
                            width={{ base: '100%', md: 'auto' }}
                            mt={{ base: 4, md: 0 }}
                            justifyContent={'center'}
                        >

                            <FiPlusCircle size={'22px'} color='white' />

                            <Text fontSize={{ md: 'xs', lg: 'md' }} fontWeight={'600'} color={'white'}>
                                Create New Admin
                            </Text>
                        </HStack>
                    </Link>

                    <CreateNewAdmin isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

                </Stack>

                <SimpleGrid
                    columns={{ base: 1, lg: 3 }}
                    spacing={8}
                    mt={12}
                >

                    {adminMembers.map((admin, idx) => {
                        return (
                            <Card key={idx}>
                                <HStack px={6} py={4} justifyContent={'space-between'}>
                                    <HStack>
                                        {admin.profile_picture ?
                                            (<Image
                                                src={admin.profile_picture}
                                                w={'70px'}
                                                h={'70px'}
                                                border={'2px'}
                                                borderColor='primary.800'
                                                boxShadow={'lg'}
                                                borderRadius="50%"
                                            />)
                                            : (
                                                <Image src='./user_demo.png' w={'75px'} />
                                            )}

                                        <VStack alignItems={'start'} spacing={1}>
                                            {admin.first_name ?
                                                <Text fontSize={'md'} fontWeight={'600'}>{admin.first_name + " " + admin.last_name}</Text>
                                                :
                                                <Text fontSize={'md'} fontWeight={'600'}>NA</Text>
                                            }
                                            <Text fontSize={'sm'} color={'grey'}>{admin.email}</Text>
                                            <Text fontSize={'sm'} color='primary.800' fontWeight={'400'}>Role : Admin</Text>
                                        </VStack>
                                    </HStack>

                                    <Menu>
                                        <MenuButton>
                                            <TfiMoreAlt size={'18px'} color={'grey'} />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem minH='40px'>
                                                <Link to={'/admin-management/view'} state={admin}>
                                                    <Text pl={1}>View User Account</Text>
                                                </Link>
                                            </MenuItem>

                                            <MenuItem minH='40px'>
                                                <Link onClick={() => { setSelectedId(admin._id); setIsConfirmDialogOpen(true); }}>
                                                    <Text color={'red'} pl={1}>Delete User Account</Text>
                                                </Link>
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </HStack>
                            </Card>
                        );
                    })}
                </SimpleGrid>
            </Container>

            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Are you sure?"
                body="This action cannot be undone."
            />
        </>
    )
}
