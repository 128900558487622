import { Card, Container, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function ContentManagement() {
    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize="lg" fontWeight="500">Content Management</Text>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                alignItems={{ base: 'center', md: 'start' }}
            >
                <Card
                    as={Link} 
                    to={'/content-management/faq'}
                    mt='5'
                    boxShadow={'lg'}
                    py={10}
                    w={{ base: '70%', md: '20%' }}
                    alignItems={'center'}
                    cursor='pointer'
                    _hover={{ boxShadow: 'xl' }}
                    textDecoration='none' 
                >
                    <Text className='custom' fontSize='md' color='primary.900'>
                        FAQ
                    </Text>
                </Card>
            </Stack>
        </Container>
    )
}
