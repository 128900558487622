import { Box, HStack, Text, VStack, Image } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { MdDashboard } from "react-icons/md";
import { primarycolor } from '../../config';
import { FaUserGear } from "react-icons/fa6";
import { MdOutlineSubscriptions } from "react-icons/md";
import { LiaGiftsSolid } from "react-icons/lia";
import { CgNotes } from "react-icons/cg";
import { PiUsersDuotone } from "react-icons/pi";
import { FaRegStar } from "react-icons/fa";
import { IoTicketOutline } from "react-icons/io5";
import { IoIosSwitch } from "react-icons/io";


export default function SidebarLinks({ onClick }) {
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
    })
    return (
        <>
            <VStack
                onClick={onClick}
                alignItems='start'
                w='100%'
                overflowY='auto'
                h='100vh'
                maxW='300px'
                // bg={primarycolor}
                bgImage="url('./navigation_bg.png')"
                borderTopRightRadius="28px"
            // borderBottomRightRadius="4xl"
            // borderTopRightRadius="40 ml={4}px"
            // borderBottomRightRadius="40 ml={4}px"
            >
                <Box pt={6} w='100%' zIndex={100}>
                    <Link to={'/#'}>
                        <HStack alignItems='center' justifyContent='center' w='100%' mb='4'>
                            <Image src='/logo.png' w='100px' />
                        </HStack>
                    </Link>

                    <Link to={'/dashboard'}>
                        <HStack
                            _hover={{ bg: 'white', color: primarycolor }}
                            bg={location.pathname.includes("dashboard") ? 'white' : ''}
                            color={location.pathname.includes("dashboard") ? 'primary.800' : 'white'}
                            p='4'
                        >
                            <MdDashboard size='20px' />
                            <Text fontWeight='500'>Dashboard</Text>
                        </HStack>
                    </Link>

                    <Link to={'/user-management'}>
                        <HStack
                            _hover={{ bg: 'white', color: primarycolor }}
                            bg={location.pathname.includes("user-management") ? 'white' : ''}
                            color={location.pathname.includes("user-management") ? 'primary.800' : 'white'}
                            p='4'
                        >
                            <FaUserGear size='20px' />
                            <Text fontWeight='500'>User Management</Text>
                        </HStack>
                    </Link>

                    <Link to={'/subscription-plan'}>
                        <HStack
                            _hover={{ bg: 'white', color: primarycolor }}
                            bg={location.pathname.includes("subscription-plan") ? 'white' : ''}
                            color={location.pathname.includes("subscription-plan") ? 'primary.800' : 'white'}
                            p='4'
                        >
                            <MdOutlineSubscriptions size='20px' />
                            <Text fontWeight='500'>Subscription Plan</Text>
                        </HStack>
                    </Link>

                    <Link to={'/content-management'}>
                        <HStack
                            cursor='pointer'
                            _hover={{ bg: 'white', color: primarycolor }}
                            bg={location.pathname.includes("content-management") ? 'white' : ''}
                            color={location.pathname.includes("content-management") ? 'primary.800' : 'white'}
                            p='4'
                        >
                            <CgNotes size='20px' />
                            <Text fontWeight='500'>Content Management</Text>
                        </HStack>
                    </Link>

                    <Link to={'/ticket-management'}>
                        <HStack
                            cursor='pointer'
                            _hover={{ bg: 'white', color: primarycolor }}
                            bg={location.pathname.includes("ticket-management") ? 'white' : ''}
                            color={location.pathname.includes("ticket-management") ? 'primary.800' : 'white'}
                            p='4'
                        >
                            <IoTicketOutline size='20px' />
                            <Text fontWeight='500'>Ticket Management</Text>
                        </HStack>
                    </Link>
                </Box>

                <Image
                    zIndex={10}
                    position='absolute'
                    bottom='0px'
                    left='0px'
                    src='/leaf-right.png'
                    h={'400px'}
                    w={{ base: '300px', md: '310px' }}
                />
            </VStack >
        </>
    )
}



