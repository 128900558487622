import {
    Image,
    Button,
    Container,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Stack,
    Text,
    VStack,
    FormLabel,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import { FaRegUserCircle } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { BiPhoneCall } from "react-icons/bi";
import { LiaEditSolid } from "react-icons/lia";
import { PiCakeFill } from "react-icons/pi";
import { TbGenderAndrogyne } from "react-icons/tb";
import { GiBodyHeight } from "react-icons/gi";
import { HiMiniUsers } from "react-icons/hi2";
import { useUsers } from '../../providers/UserProvider';
import { useLocation } from 'react-router-dom';

export default function UserDetail() {
    const { banUser, activeUser } = useUsers();
    const { state } = useLocation();

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formattedDateOfBirth = state.date_of_birth ? formatDate(state.date_of_birth) : "NA";

    const banUserFunction = async (userId) => {
        await banUser(userId);
    }

    const activeUserFunction = async (userId) => {
        await activeUser(userId);
    }

    const formik = useFormik({
        initialValues: {
            f_name: state.first_name || "NA",
            l_name: state.last_name || "NA",
            gender: state.userGender || "NA",
            email: state.email || "NA",
            birth: formattedDateOfBirth,
            old: state.userOld || "NA",
            height: state.height || "NA",
            race: state.userRace || "NA",
            location: state.location || "NA",
            interest_hobbies: state.interest_hobbies || "NA",
        },
        onSubmit: async (values) => {
            console.log(values);
        }
    });

    useEffect(() => {
        console.log(state);
    }, [])

    return (
        <Container maxWidth='9xl' px={6}>
            <Stack direction={{ base: 'column', md: 'row' }} w={'100%'} justifyContent={'space-between'}>
                <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="500">
                    <Link to='/user-management'>
                        User Management &gt;{' '}
                    </Link>
                    <Text as="span" color="black" pl={2} fontWeight={700}>
                        {state.first_name}
                    </Text>
                    <Text as="span" color="black" pl={2} fontWeight={700}>
                        {state.last_name}
                    </Text>
                </Text>

                {/* <Menu>
                    <MenuButton>
                        <Box
                            mt={{ base: 4, md: 0 }}
                            px={4}
                            py={2}
                            border={'1px'}
                            borderRadius={'md'}
                            borderColor={'#F2F2F2'}
                        >
                            <HStack justifyContent={{ base: 'space-between' }}>
                                <Text fontSize={'sm'}>More Options</Text>
                                <IoMdArrowDropdown size={25} />
                            </HStack>
                        </Box>
                    </MenuButton>
                    <MenuList >
                        <MenuItem minH='30px'>
                            {state.is_banned ?
                                <Link onClick={() => activeUserFunction(state._id)}>
                                    <Text fontSize={'sm'} pl={1}>Active User Account</Text>
                                </Link>
                                :
                                <Link onClick={() => banUserFunction(state._id)}>
                                    <Text fontSize={'sm'} pl={1}>Suspend User Account</Text>
                                </Link>
                            }
                        </MenuItem>
                    </MenuList>
                </Menu> */}
            </Stack>

            <Stack direction={{ base: 'column', md: 'row' }} mt={8} w={'100%'} spacing={12} >
                <VStack spacing={0} ml={{ md: 16 }} mt={6}>
                {state.profile_picture ?
                        <Image
                            src={state.profile_picture}
                            alt='user'
                            boxSize="120px"
                            borderRadius="full"
                        />
                        :
                        <Image src='../user_demo.png' width={'120px'} alt='user' />
                    }
                    <Text fontSize="lg" fontWeight="500">
                        {state.first_name ? <Text>{state.first_name}<Text as={'spam'} pl={1}>{state.last_name}</Text></Text>
                            : <Text>NA</Text>}
                    </Text>
                    <Text fontSize="sm">{state.email}</Text>
                </VStack>

                <form onSubmit={formik.handleSubmit}>
                    <VStack w={'100%'} alignItems={'start'} ml={{ md: 12 }} mt={6}>
                        <HStack w={'100%'} justifyContent={'space-between'}>
                            <Text className='custom' fontSize={'sm'} pb={1} fontWeight={500} color='primary.200'>Basic Details</Text>
                            {/* <LiaEditSolid size={20} /> */}
                        </HStack>
                        <SimpleGrid w={'100%'} columns={{ base: 1, lg: 2 }} spacing={3}>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="f_name"
                                        name="f_name"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.f_name}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="l_name"
                                        name="l_name"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.l_name}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<TbGenderAndrogyne size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="gender"
                                        name="gender"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.gender}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<IoIosMail size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<PiCakeFill size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="birth"
                                        name="birth"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.birth}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<HiMiniUsers size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="old"
                                        name="old"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.old}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<GiBodyHeight size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="height"
                                        name="height"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.height}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<HiMiniUsers size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="race"
                                        name="race"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        fontSize="sm"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.800",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.race}
                                        onChange={formik.handleChange}
                                        isReadOnly
                                    />
                                </InputGroup>
                            </FormControl>

                        </SimpleGrid>
                        <FormControl mt={4}>
                            <FormLabel className='custom' fontSize={'sm'} fontWeight={500} color='primary.200' >Location</FormLabel>
                            <Input
                                id="location"
                                name="location"
                                type="text"
                                variant="filled"
                                required={true}
                                fontSize="sm"
                                py={6}
                                px={4}
                                bg="none"
                                borderColor="gray.100"
                                _focus={{
                                    borderColor: "primary.800",
                                }}
                                borderRadius="10"
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                isReadOnly
                            />
                        </FormControl>

                    </VStack>
                </form>
            </Stack>
        </Container>
    )
}