import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";

const AdminContext = createContext();

const AdminProvider = ({ children }) => {
    const [adminMembers, setAdminMembers] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, user, logged, logout } = useAuth();

    async function addAdminMember(
        data,
    ) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(`${url}/admin/add`,
                data,
            );
            await getAdminMembers();
            toast.success("Added !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.errors);
            }
            toast.error(error.response.data.errors);
            throw "Error";
        }
    }

    async function updateAdminMember(
        id, first_name, last_name, bio, phone, profile_picture,
    ) {
        console.log(id, profile_picture, first_name, last_name, bio, phone)
        setLoading(true);
        if (!logged) {
            return;
        }
        // using multipart formdata to upload images
        var formData = new FormData();
        formData.append('profile_picture', profile_picture);
        // adding other fields 
        formData.append('id', id);
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('bio', bio);
        formData.append('phone', phone);
        // formData.append('password', password);

        console.log(formData);
        try {
            const res = await axios.put(
                `${url}/admin/update`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            await getAdminMembers();
            toast.success("Update !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.errors);
            }
            toast.error(error.response.data.errors);
            throw "Error";
        }
    }

    async function updateAdminPassword(id, password) {
        console.log(id, password);
        setLoading(true);
    
        if (!logged) {
            return;
        }
    
        try {    
            const res = await axios.put(
                `${url}/admin/update/password`,
                {
                    id,
                    password
                }, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
    
            await getAdminMembers();
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 400) {
                console.log(error.response.data.errors);
            } else {
                 console.log('An error occurred while updating the password.');
            }
            throw new Error("Error updating password");
        } finally {
            setLoading(false);
        }
    }        

    async function deleteAdmin(id) {
        console.log("Delete Admin");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { res } = await axios.delete(`${url}/admin/delete`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "id": id,
                    },
                }
            );
            await getAdminMembers();
            toast.success("Deleted !");
            return true;

        } catch (error) {
            console.error("DELETE ADMIN ERROR");
            console.error(error);
            toast.error(error.response.data.error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            return false;
        }
    }

    async function getAdminMembers() {
        console.log("Get AdminMembers");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/admin-users`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setAdminMembers(data.data);

        } catch (error) {
            console.error("GET ADMIN ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            return "err";
        }
    }

    async function deleteAdminMember(id) {
        console.log(id)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/memberships/delete`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                data: {
                    "id": id,
                },
            }
            );
            await getAdminMembers();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            toast.error("Can't delete active Location With Members.");
            return "err";
        }
    }

    useEffect(() => {
        getAdminMembers();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            adminMembers,
            addAdminMember,
            updateAdminMember,
            updateAdminPassword,
            deleteAdmin,
            getAdminMembers,
            deleteAdminMember,
        }),
        [logged, loading, adminMembers] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>
    );
};

export const useAdminMembers = () => {
    return useContext(AdminContext);
};

export default AdminProvider;
