import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/home_dashboard/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AuthProvider from './providers/AuthProvider';
import PrivateRoutes from './components/utils/PrivateRoutes';
import Login from './components/auth/Login';
import OtpPage from './components/auth/OtpPage';
import UserManagement from './components/user_management/UserManagement';
import UserDetail from './components/user_management/UserDetail';
import SubscriptionPlan from './components/subscription_plan/SubscriptionPlan';
import ManageYourPlan from './components/subscription_plan/ManageYourPlan';
import EditSubscriptionPlan from './components/subscription_plan/EditSubscriptionPlan';
import ViewSubscriptionPlan from './components/subscription_plan/ViewSubscriptionPlan';
import ContentManagement from './components/content_management/ContentManagement';
import Faq from './components/content_management/Faq';
import AddSubscriptionPlan from './components/subscription_plan/AddSubscriptionPlan';
import TicketManagement from './components/ticket_management/TicketManagement';
import Unresolved from './components/ticket_management/Unresolved';
import Resolved from './components/ticket_management/Resolved';
import AdminManagement from './components/admin_management/AdminManagement';
import ViewAdminAccount from './components/admin_management/ViewAdminAccount';
import Setting from './components/settings/Setting';
import YourProfileDetails from './components/settings/YourProfileDetails';
import PushNotifications from './components/push_notification/PushNotifications';

function App() {
  return (
    <AuthProvider key='auth'>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/dashboard' element={<Dashboard />} />

            <Route path='/admin-management' element={<AdminManagement />} />
            <Route path='/admin-management/view' element={<ViewAdminAccount />} />

            <Route path='/setting' element={<Setting />} />
            <Route path='/setting/your-profile' element={<YourProfileDetails />} />

            <Route path='/setting/push-notification' element={<PushNotifications />} />

            <Route path='/user-management' element={<UserManagement />} />
            <Route path='/user-management/user-details' element={<UserDetail />} />

            <Route path='/subscription-plan' element={<SubscriptionPlan />} />
            <Route path='/subscription-plan/add' element={<AddSubscriptionPlan />} />
            <Route path='/subscription-plan/manage-your-plan' element={<ManageYourPlan />} />
            <Route path='/subscription-plan/manage-your-plan/edit' element={<EditSubscriptionPlan />} />
            <Route path='/subscription-plan/manage-your-plan/view' element={<ViewSubscriptionPlan />} />

            <Route path='/content-management' element={<ContentManagement />} />
            <Route path='/content-management/faq' element={<Faq />} />

            <Route path='/ticket-management' element={<TicketManagement />} />
            <Route path='/ticket-management/unresolved' element={<Unresolved />} />
            <Route path='/ticket-management/resolved' element={<Resolved />} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/otp-page' element={<OtpPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
