import {
    Box,
    HStack,
    Flex,
    Text,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuList,
    VStack,
    MenuItem,
    Container,
    Card,
    Image,
} from '@chakra-ui/react'
import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from './SideBar';
import { IoNotificationsOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from "react-icons/md";
import { useAuth } from '../../providers/AuthProvider';

export default function Navbar() {
    let navigate = useNavigate();

    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });

    const { user, logout } = useAuth();

    const handleLogout = () => {
        console.log('logout');
        logout();
        navigate('/')
    };

    return (
        <>
            <Box bg='white' py={2} px={4} w='100%' boxShadow={'md'}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>

                    {sideBarVariant === 'drawer' && (
                        // <IconButton
                        //     size={'md'}
                        //     icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        //     aria-label={'Open Menu'}
                        //     display={{ xl: 'none' }}
                        //     onClick={isOpen ? onClose : onOpen}
                        // />
                        <SideBar />
                    )}

                    <Flex w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text as='h1' pl={{ base: 2, md: 0 }} fontSize={{ base: 'sm', md: 'xl' }} fontWeight={700}>
                                Welcome, {user.first_name} {user.last_name}
                            </Text>
                            <HStack spacing={6}>
                                <Menu>
                                    <MenuButton>
                                        <HStack spacing='2'>
                                            {user.profile_picture ?
                                                <Image src={user.profile_picture} w="12" h="12" objectFit="cover" borderRadius="50%" />
                                                :
                                                <img src='./logo.png' width={40} alt='logo' />
                                            }
                                            <VStack spacing={0} alignItems={'start'}>
                                                <Text fontSize={'sm'} fontWeight={600}>{user.first_name}</Text>
                                                <Text fontSize={'sm'}>Admin</Text>
                                            </VStack>
                                            <MdKeyboardArrowDown size='20' opacity={'0.8'} />
                                        </HStack>
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem minH='40px'>
                                            <Link to={'/setting'}>
                                                <Text pl={1}>Settings</Text>
                                            </Link>
                                        </MenuItem>

                                        <MenuItem minH='40px'>
                                            <Link to={'/admin-management'}>
                                                <Text pl={1}>Admin Management</Text>
                                            </Link>
                                        </MenuItem>

                                        <MenuItem minH='40px' onClick={handleLogout}>
                                            <Text pl={1}>Log Out</Text>
                                        </MenuItem>

                                    </MenuList>
                                </Menu>
                            </HStack>
                        </HStack>
                    </Flex>
                </Flex>
            </Box >
            <ToastContainer />
        </>
    )
}
