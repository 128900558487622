import {
    Card,
    Container,
    FormControl,
    useDisclosure,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    HStack,
    Flex,
    Center,
    Button
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiUserAddLine } from "react-icons/ri";
import { LuCalendarDays } from "react-icons/lu";
import { FiCheckSquare } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { MdMoreHoriz } from "react-icons/md";
import { useUsers } from '../../providers/UserProvider';
import { ConfirmDialog } from '../utils/ConfirmDialog';
import { primarycolor } from '../../config';
import UserLocation from './UserLocation';

export default function UserManagement() {
    const {
        users,
        deleteUser,
        totalUser,
        totalUserThisMonth,
        totalActiveUser,
        banUser,
        activeUser,
        searchUser,
        currentPage,
        totalPages,
        getUsers
    } = useUsers();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);
    const [searchQuery, setSearchQuery] = useState("");

    const handleConfirm = async () => {
        try {
            await deleteUser(selectedId);
            setIsConfirmDialogOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            onClose();
        }
    };
    const handleClose = () => {
        setIsConfirmDialogOpen(false);
    };

    const camelCaseStyle = {
        textTransform: 'capitalize',
        whiteSpace: 'nowrap', // Prevents breaking words
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    let formattedJoinedDate;
    let formattedDobDate;

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        if (searchQuery) {
            searchUser(searchQuery);
        } else {
            getUsers(undefined, currentPage);
        }
    }, [searchQuery, currentPage]);

    const banUserFunction = async (userId) => {
        await banUser(userId);
    }

    const activeUserFunction = async (userId) => {
        await activeUser(userId);
    }

    return (
        <Container maxWidth='9xl'>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                w={'100%'}
            >
                <Stack
                    w={{ md: '70%' }}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: 2, md: 4 }}
                    alignItems={{ base: 'center', md: 'start' }}
                >
                    <Card mt='5' boxShadow={'lg'} pl={4} py={6} w={{ base: '70%', md: '25%' }}>
                        <VStack spacing={0} alignItems={'start'}>
                            <RiUserAddLine size={35} color='#4C47DF' />
                            <Text className='custom' fontSize='2xl' fontWeight={'600'} color='primary.800'>{totalUser.count}</Text>
                            <Text fontSize={'xs'}>Total Users</Text>
                        </VStack>
                    </Card>

                    <Card mt='5' boxShadow={'lg'} pl={4} py={6} w={{ base: '70%', md: '25%' }}>
                        <VStack spacing={0} alignItems={'start'}>
                            <LuCalendarDays size={35} color='#E16317' />
                            <Text className='custom' fontSize='2xl' fontWeight={'600'} color='secondary'>{totalUserThisMonth.count}</Text>
                            <Text fontSize={'xs'}>Total Users This Month</Text>
                        </VStack>
                    </Card>

                    <Card mt='5' boxShadow={'lg'} pl={4} py={6} w={{ base: '70%', md: '25%' }}>
                        <VStack spacing={0} alignItems={'start'}>
                            <FiCheckSquare size={35} color='#6EC0E5' />
                            <Text className='custom' fontSize='2xl' fontWeight={'600'} color='primary.100'>{totalActiveUser.count}</Text>
                            <Text fontSize={'xs'}>Active Users Currently on App</Text>
                        </VStack>
                    </Card>
                </Stack>

            </Stack>

            <VStack alignItems={'start'} mt={8} mb={4} mx={{ base: 6, md: 0 }} spacing={4}>
                <Text fontSize={'sm'} fontWeight={'500'}>Below is the list of users in Forrest app</Text>

                <FormControl w={{ base: '100%', md: '50%', lg: '30%' }}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <IoSearch size={'18px'} color='#E16317' />
                        </InputLeftElement>
                        <Input
                            id="search"
                            name="search"
                            type="text"
                            variant="filled"
                            required={true}
                            placeholder="Search Users"
                            fontSize={{ base: 'xs', md: 'sm' }}
                            py={4}
                            px={10}
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                        />
                    </InputGroup>
                </FormControl>

                <TableContainer w={'100%'} borderRadius="md" style={{ boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)' }}>
                    <Table variant="simple" >
                        <Thead>
                            <Tr bg="primary.50">
                                <Th style={camelCaseStyle} fontWeight={'800'}>ID</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>User Name</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Gender</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Email ID</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Birthdate</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Age</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Height</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Race</Th>
                                <Th width="460px" minWidth="460px" style={camelCaseStyle} fontWeight="800">
                                    Location
                                </Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Acc Status</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Joined Date</Th>
                                <Th style={camelCaseStyle} fontWeight={'800'}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            sx={{ 'tr:not(:last-of-type)': { borderBottom: '2px solid', borderColor: 'gray.200' } }}
                        >
                            {users.map((user, index) => (
                                formattedJoinedDate = formatDate(user.createdAt),
                                user.date_of_birth ?
                                    formattedDobDate = formatDate(user.date_of_birth)
                                    : formattedDobDate = "",
                                <Tr key={index} fontSize="sm">
                                    <Td>{index + 1}</Td>
                                    <Td color={primarycolor}>
                                        <Link to={'/user-management/user-details'} state={user}>
                                            {user.first_name ?
                                                <Text pl={{ base: 2, md: 0 }}>
                                                    {user.first_name} {user.last_name}
                                                </Text>
                                                : <Text>NA</Text>}
                                        </Link>
                                    </Td>
                                    <Td>{user.userGender}</Td>
                                    <Td>{user.email}</Td>
                                    <Td>{formattedDobDate}</Td>
                                    <Td>{user.userAge}</Td>
                                    <Td>{user.height}</Td>
                                    <Td>{user.userRace}</Td>
                                    <UserLocation location={user.location} />
                                    {user.is_banned ?
                                        <Td color={'red'}>In Active</Td>
                                        :
                                        <Td color={'green'}>Active</Td>
                                    }
                                    <Td>{formattedJoinedDate}</Td>
                                    <Td>
                                        <Menu>
                                            <MenuButton>
                                                <MdMoreHoriz />
                                            </MenuButton>
                                            <MenuList>

                                                <MenuItem minH='30px'>
                                                    {user.is_banned ?
                                                        <Link onClick={() => activeUserFunction(user._id)}>
                                                            <Text fontSize={'sm'} pl={1}>Active User Account</Text>
                                                        </Link>
                                                        :
                                                        <Link onClick={() => banUserFunction(user._id)}>
                                                            <Text fontSize={'sm'} pl={1}>Suspend User Account</Text>
                                                        </Link>
                                                    }
                                                </MenuItem>

                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

            {/* / Pagination/ */}
            <HStack w={'100%'} alignItems={'center'} justifyContent="center" mb='5'>
                <Center>
                    <Flex alignItems='center' gap='2'>
                        {currentPage == 1 || currentPage == 0 ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getUsers(undefined, currentPage - 1)}
                                ml={2}
                            >Previous</Button>
                        }
                        <Text>{`Page ${currentPage} of ${totalPages}`}</Text>

                        {(totalPages == currentPage || totalPages == 0) ? null :
                            <Button
                                colorScheme='primary'
                                variant='outline'
                                onClick={() => getUsers(undefined, (parseInt(currentPage) + 1))}
                                ml={2}
                            >Next</Button>
                        }

                    </Flex>
                </Center>
            </HStack>

        </Container>
    )
}